@import "primeng/resources/primeng.css";
@import "primeflex/primeflex.scss";

/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Roboto:300,400,500,600,700");
// Global vendors
//@import "~bootstrap/dist/css/bootstrap.css";
// => Material
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";

@import "~highlight.js/styles/googlecode.css";
//@import "~ngx-scrollbar/css/ngx-scrollbar.css";

// Global fonts
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";

//Custom Dynamic Height Variable
$windowHeight: 100vh;
$window-height-offset: 100px;

body {
  background: #eef0f8;
  background-repeat: no-repeat;
  background-position: center top;
}

// Modify this to change the size of EVERYTHING in the app. Default is 12px.
html {
  font-size: 12px;
}

.layout {
  background: #eef0f8;
  font-family: var(--font-family);
}

//PRIMENG OVERRIDES

// Long toast detail was hiding close button
.p-toast-message-text {
  overflow-wrap: break-word;
  max-width: 18.5rem;
}

//Aligns the 'Results per page' after
.p-paginator-right-content{
  margin-left: 0;
}

//Table Row Height padding
.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.4rem 1rem;
}

//Remove default padding on PrimeNg Content
.p-sidebar .p-sidebar-content,
.p-sidebar-footer {
  padding: 0;
}

//Enables scroll panel useage within sidebar content
.p-sidebar .p-sidebar-content {
  overflow: hidden;
  height: 100%;
}

//Rounded the corners on the tab menu, and consistant background independant of styles
.p-tabmenu-nav {
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  background-color: var(--surface-50);
}

// Added new 'button type' colour style
.p-button-tool {
  background: var(--surface-0);
  border-color: transparent;
  color: var(--surface-900);
  padding: 0.25rem 1rem;
  margin: 0;
}

.m-table-wrapper {
  max-height: 100%;
  overflow: auto;

  @media (min-width: 576px) {
    max-height: 50vh;
    overflow: auto;
  }
}

//Header menu dialogs
.m-dialog-wrapper {
  width: 100vw !important;
  height: 100vh !important;
  max-height: 100%;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;

  .p-dialog-header {
    border-radius: 0px;
  }

  .p-dialog-content {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    padding: 1.5rem 1.5rem 2rem 1.5rem;
  }

  @media (min-width: 576px) {
    width: auto !important;
    height: auto !important;
    min-width: 600px;
    position: relative;

    .p-dialog-header {
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
    }

    .p-dialog-content {
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
      padding: 0 1.5rem 2rem 1.5rem;
    }
  }
}

//PRIMENG EXTENSIONS

.max-w-48rem {
  max-width: 48rem;
}
.max-h-96dvh {
  max-height: 96dvh;
}
